import React from "react";
import { graphql, Link } from "gatsby";
import { format } from "date-fns";

import SEO from "../components/seo";
import {
  mapEdgesToNodes,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";

import { boxUl } from "../components/box-list.module.css";

export const query = graphql`
  query ChroniclePageQuery {
    posts: allSanityChroniclePost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          title
          publishedAt
          slug {
            current
          }
        }
      }
    }
  }
`;

const ChroniclePage = ({ data }) => {
  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const publishedNodes =
    postNodes && postNodes.filter(filterOutDocsPublishedInTheFuture);
  const hasPosts = !!publishedNodes?.length;

  return (
    <>
      <SEO title="Chronicle" />
      <ul className={boxUl}>
        <li>
          <h1>Chronicle{!hasPosts && " (coming soon)"}</h1>
        </li>
        {hasPosts &&
          publishedNodes.map((post) => (
            <li key={post.id}>
              <Link to={post.slug.current}>
                {format(new Date(post.publishedAt), "yyMMdd")}&nbsp;–{" "}
                {post.title}
              </Link>
            </li>
          ))}
      </ul>
    </>
  );
};

export default ChroniclePage;
